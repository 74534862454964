import React from "react";
import classnames from "classnames";

import SEO from "@components/seo/seo";

/* Import Local Styles */
import * as styles from "./policy.module.scss";

const Policy = ({ pageContext: { policy } }) => {
  const policyTitle = {
    "Refund policy": "Return Guidelines",
    "Shipping policy": "Shipping & Handling",
    "Terms of service": "Terms of service",
  };
  return (
    <div className={classnames("policy", styles.policy)}>
      <h1>
        {policyTitle[policy.title] ? policyTitle[policy.title] : policy.title}
      </h1>
      <div
        dangerouslySetInnerHTML={{ __html: policy.body }}
        className={classnames("body", styles.body)}
      />
    </div>
  );
};

export default Policy;

export const Head = () => <SEO />;
